/* eslint-disable no-unreachable */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { cloneElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import i18n from "i18n";
import SettingsActions from "redux-react/actions/settingsActions";
import FormActions from "redux-react/actions/formAction";
import FormDictionaryDialog from "components/Custom/FormDictionary";

import HandleTextSelection from "helpers/textSelection";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/Basics/MDButton";

import AddLabelDialog from "./labels/AddLabelDialog";
import AddChartDialog from "./charts/AddChartDialog";

const GloabAppSettingsComponent = ({ children }) => {
	const dispatch = useDispatch();
	const [modal, setModal] = useState({ open: false, action: null, data: {} });
	const profile = useSelector(selectCurrentProfile);

	const getModalOpen = action => {
		return modal.open && modal.action === action;
	};

	const getModalData = action => {
		if (action === modal.action) return modal.data;
		return null;
	};

	const closeModal = () => {
		setModal({ open: false, action: null, data: {} });
	};

	const handleEditChart = (code, cb) => {
		setModal({
			open: true,
			action: "add-chart",
			data: {
				edit: true,
				code,
				step: 3,
				cb
			}
		});
	};

	const insertFormItem = data => {
		const onSuccess = res => {
			setModal({
				open: true,
				action: "form-dialog",
				data: {
					APIs: res.APIs,
					route: data.route,
					valuesDictionary: res.valueDictionary,
					formBuildDictionary: res.formBuilderDictionary,
					contextDictionary: res.dictionary,
					PIaccess: data.PI,
					isEmpty: data.empty,
					target: data.form.collectionDefault,
					selectedId: data.selectedId,
					routeDict: data.form.routeDictionary,
					forceEdit: Boolean(data.form.routeDictionary === "dictionary"),
					callback: data.callback
				}
			});
		};

		dispatch(
			FormActions.getItemEmpty(data.form.collectionDefault, data.form.routeDictionary, onSuccess)
		);
	};

	const updateFormItem = data => {
		const onSuccess = res => {
			setModal({
				open: true,
				action: "form-dialog",
				data: {
					APIs: res.APIs,
					route: data.route,
					valuesDictionary: res.valueDictionary,
					formBuildDictionary: res.formBuilderDictionary,
					contextDictionary: res.dictionary,
					PIaccess: data.PI,
					isEmpty: data.empty,
					target: data.form.collectionDefault,
					selectedId: data.selectedId,
					routeDict: data.form.routeDictionary,
					forceEdit: Boolean(data.form.routeDictionary === "dictionary"),
					callback: data.callback
				}
			});
		};

		dispatch(
			FormActions.getItemByID(
				data.selectedId,
				data.form.collectionDefault,
				data.form.routeDictionary,
				onSuccess
			)
		);
	};

	const deleteFormItem = data => {
		const confirmDelete = () => {
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("FORMS.deleteSuccess"),
						type: "success"
					})
				);

				closeModal();
				data.callback();
			};

			dispatch(FormActions.deleteItem(data.item._id, data.target, { delete: true }, onSuccess));
		};

		setModal({
			open: true,
			action: "confirm-delete",
			data: {
				item: data.item,
				target: data.target,
				confirmDelete: confirmDelete
			}
		});
	};

	const actionSaveOrCreateItem = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);

			closeModal();
			getModalData("form-dialog")?.callback();
			callback(true);
		};
		let data = { values, target: getModalData("form-dialog")?.target, unique };
		if (getModalData("form-dialog")?.isEmpty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(getModalData("form-dialog")?.selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	const handleActionContextMenu = (action, data) => {
		switch (action) {
			case "add-label":
				setModal({
					open: true,
					action,
					data
				});
				break;
			default:
				break;
		}
	};

	return (
		<>
			{/* <HandleTextSelection handleAction={handleActionContextMenu} /> */}
			{/* Add label */}
			<AddLabelDialog
				open={getModalOpen("add-label")}
				handleCloseDialog={closeModal}
				handleSave={({ code, label, color }) => {
					const onSuccess = res => {
						dispatch(
							display({
								message: i18n.t("SETTINGS.LABELS.SUCCESS.add"),
								type: "success"
							})
						);
					};
					dispatch(
						SettingsActions.newSetting(
							profile.assistantID,
							"label",
							{ code, label, color },
							onSuccess
						)
					);
				}}
				possibleCode={getModalData("add-label")}
			/>
			{/* Edit chart */}
			<AddChartDialog
				open={getModalOpen("add-chart")}
				handleCloseDialog={closeModal}
				handleSave={({ chart, edit }) => {
					getModalData("add-chart")?.cb(chart, edit);
				}}
				collections={getModalData("add-chart")?.collections}
				edit={getModalData("add-chart")?.edit}
				chart={getModalData("add-chart")?.chart}
				step={getModalData("add-chart")?.step}
				code={getModalData("add-chart")?.code}
			/>
			{cloneElement(children, { handleEditChart, insertFormItem, updateFormItem, deleteFormItem })}
			{/* Form part */}
			{getModalOpen("form-dialog") && (
				<FormDictionaryDialog
					open={getModalOpen("form-dialog")}
					route={getModalData("form-dialog")?.route}
					handleCloseDialog={closeModal}
					handleSave={(values, unique, callback, rolesProfileUser) => {
						actionSaveOrCreateItem(values, unique, callback, rolesProfileUser);
					}}
					listAPIs={getModalData("form-dialog")?.APIs}
					valuesDictionary={getModalData("form-dialog")?.valuesDictionary}
					formBuildDictionary={getModalData("form-dialog")?.formBuildDictionary}
					contextDictionary={getModalData("form-dialog")?.contextDictionary}
					PIaccess={getModalData("form-dialog")?.PIaccess}
					isEmpty={getModalData("form-dialog")?.isEmpty}
					target={getModalData("form-dialog")?.target}
					selectedId={getModalData("form-dialog")?.selectedId}
					routeDict={getModalData("form-dialog")?.routeDict}
					forceEdit={getModalData("form-dialog")?.forceEdit}
				/>
			)}

			{getModalOpen("confirm-delete") && (
				<Dialog open={getModalOpen("confirm-delete")} onClose={closeModal}>
					<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
					<DialogContent>{i18n.t("FORMS.LABELS.confirmDelete")}</DialogContent>
					<DialogActions>
						<MDButton autoFocus onClick={closeModal} variant="outlined" color="dark">
							{i18n.t("FORMS.cancel")}
						</MDButton>
						<MDButton
							onClick={getModalData("confirm-delete")?.confirmDelete}
							color="dark"
							variant="contained"
							autoFocus
						>
							{i18n.t("FORMS.validate")}
						</MDButton>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default GloabAppSettingsComponent;
