import { createContext, useContext, useMemo, useReducer } from "react";

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
	switch (action.type) {
		case "MINI_SIDENAV": {
			return { ...state, miniSidenav: action.value };
		}
		case "OPEN_CONFIGURATOR": {
			return { ...state, openConfigurator: action.value };
		}
		case "DARKMODE": {
			return { ...state, darkMode: action.value };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
	const initialState = {
		miniSidenav: false,
		openConfigurator: false,
		darkMode: false,
		isPhone: window.innerWidth < 960
	};

	const [controller, dispatch] = useReducer(reducer, initialState);

	const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

	return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
	const context = useContext(MaterialUI);

	if (!context) {
		throw new Error(
			"useMaterialUIController should be used inside the MaterialUIControllerProvider."
		);
	}

	return context;
}

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
	MaterialUIControllerProvider,
	useMaterialUIController,
	setMiniSidenav,
	setOpenConfigurator,
	setDarkMode
};
